<template>
  <div>
    <confirm-delete
      ref="modal"
      :msg="deleteMsg"
      :title="titleMsg"
      @on-submit="deleteLanguage"
    />
    <confirm-disable
      ref="disableModal"
      :msg="disableMsg"
      @on-submit="switchLanguageStatus"
      @on-hide="resetStatus"
    />
    <!-- <loader :is-visible="isLoading" /> -->
    <!-- Table Container Cards -->
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        v-if="!contentLoading"
        ref="refUserListTable"
        class="position-relative"
        :items="items"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
        <!-- Column: User -->
        <template #cell(user)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="32"
                :src="data.item.avatar"
                :text="avatarText(data.item.firstName)"
                :variant="`light-${resolveUserRoleVariant(data.item.roleId)}`"
              />
            </template>
            {{ data.item.firstName }} {{ data.item.lastName }}
          </b-media>
        </template>

        <!-- Column: Role -->
        <template #cell(role)="data">
          <div class="text-nowrap">
            <feather-icon
              :icon="resolveUserRoleIcon(data.item.roleId)"
              size="18"
              class="mr-50"
              :class="`text-${resolveUserRoleVariant(data.item.roleId)}`"
            />
            <span class="align-text-top text-capitalize">{{
              resolveUserRoleName(data.item.roleId)
            }}</span>
          </div>
        </template>

        <!-- Column Status -->
        <template #cell(status)="data">
          <div>
            <b-form-checkbox
              v-model="data.item.isActive"
              class="custom-control-primary"
              name="check-button"
              switch
              @change="
                openDisableModal(
                  data.item.id,
                  data.item.isActive,
                  data.item.name
                )
              "
            />
          </div>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            v-if="checkAccess.Edit || checkAccess.delete"
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item
              v-if="
                checkLoginRole() === RoleEnum.SuperAdmin
                  ? true
                  : checkAccess.Edit
              "
              :to="{ name: 'language-edit', params: { id: data.item.id } }"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <Loader v-if="contentLoading" />
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <!-- <span class="text-muted">Showing  of {{ totalUsers }} entries</span> -->
            <span class="text-muted">Showing
              {{ totalLanguages >= 1 ? startIndex + 1 : startIndex }} to
              {{ endIndex > totalLanguages ? totalLanguages : endIndex }} of
              {{ totalLanguages }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :per-page="perPage"
              :total-rows="totalLanguages"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BTable,
  BMedia,
  BAvatar,
  BDropdown,
  BDropdownItem,
  BPagination,
  BFormCheckbox
} from "bootstrap-vue";
import vSelect from "vue-select";
import {
  perPageOptions,
  perPage,
  currentPage,
  deleteConfirmMsg,
  resConditionCheck,
  RoleEnum
} from "@/helpers/constant";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import errorResponseHandler from "@/services/errorHandler";
import checkLoginRole from "@/helpers/checkLoginRole";
import accessRightCheck from "@/helpers/accessRightCheck";
import Loader from "@/layouts/skeloton-loader/Loader.vue";
import ConfirmDelete from "../../confirm-delete/ConfirmDelete.vue";
import ConfirmDisable from "../../confirm-disable/ConfirmDisable.vue";
import LanguageService from "../../../services/language/language.service";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BMedia,
    BAvatar,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    ConfirmDelete,
    BFormCheckbox,
    ConfirmDisable,
    Loader
  },

  data() {
    return {
      contentLoading: true,
      searchQuery: "",
      sortBy: "",
      perPageOptions,
      perPage,
      totalLanguages: 0,
      isSortDirDesc: false,
      currentPage,
      disableMsg: "",
      deleteMsg: "",
      titleMsg: "",
      languageId: "",
      checkAccess: {},
      checkLoginRole,
      RoleEnum,
      status2: "",
      items: [],
      startIndex: 0,
      endIndex: 0,
      errorData: "",
      tableColumns: [
        { key: "name", sortable: true },
        { key: "iso_1Code", sortable: true },
        { key: "iso_2Code" },
        { key: "status" },
        { key: "actions" }
      ]
    };
  },
  watch: {
    currentPage: {
      handler() {
        this.provider();
      }
    },

    perPage: {
      handler() {
        this.currentPage = 1;
        this.provider();
      }
    },

    sortBy: {
      handler() {
        this.provider();
      }
    },

    searchQuery: {
      handler() {
        this.currentPage = 1;
        this.provider();
      }
    },

    isSortDirDesc: {
      handler() {
        this.provider();
      }
    }
  },
  mounted() {
    this.checkAccess = accessRightCheck(this.$route.meta.module.name);
    if (
      this.checkLoginRole() !== RoleEnum.SuperAdmin
      && !this.checkAccess.Edit
    ) {
      this.tableColumns = [
        { key: "name", sortable: true },
        { key: "iso_1Code", sortable: true },
        { key: "iso_2Code" },
        { key: "status" }
      ];
    }
    this.provider();
  },
  methods: {
    provider() {
      this.contentLoading = true
      const payload = {
        no_of_result: this.perPage,
        page: this.currentPage,
        order: this.isSortDirDesc === true ? "DESC" : "ASC",
        order_by: this.sortBy,
        search: this.searchQuery
      };
      LanguageService.getAllLanguages(payload)
        .then(res => {
          if (resConditionCheck(res.status) && res.data.data) {
            this.contentLoading = false;
            this.items = res.data.data;
            this.totalLanguages = res.data.count;
            this.startIndex = this.currentPage * this.perPage - this.perPage;
            this.endIndex = this.startIndex + this.perPage;
          }
        })
        .catch(error => {
          const errorData = errorResponseHandler(error);
          this.contentLoading = false;
          if (error.response.status === 404) {
            this.items = [];
            this.totalLanguages = 0;
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorData,
                icon: "X-CircleIcon",
                variant: "danger"
              }
            });
          }
        });
    },
    openModal(id, name) {
      this.titleMsg = deleteConfirmMsg;
      this.deleteMsg = `Are you sure you want to delete ${name} language ?`;
      this.languageId = id;
      this.$refs.modal.show();
    },
    openDisableModal(id, status, name) {
      this.languageId = id;
      this.status2 = !status;
      this.$refs.disableModal.show();
      this.disableMsg = !status
        ? `Are you sure you want to deactivate ${name} language ?`
        : `Are you sure you want to activate ${name} language ?`;
    },
    resetStatus() {
      const foundIdex = this.items.findIndex(
        item => item.id === this.languageId
      );
      this.$set(this.items[foundIdex], "isActive", this.status2);
    },
    deleteLanguage(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.$refs.modal.loading = true;
      if (this.languageId) {
        LanguageService.deleteLanguage(this.languageId)
          .then(res => {
            if (resConditionCheck(res.status)) {
              this.$refs.modal.loading = false;
              this.$refs.modal.toggleModal = false;
              this.items = this.items.filter(
                item => item.id !== this.languageId
              );
              if (this.items.length === 0 && this.currentPage !== 1) {
                this.currentPage -= 1;
                this.provider();
              } else {
                this.provider();
              }
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: res.data.message,
                  icon: "CheckIcon",
                  variant: "success"
                }
              });
            }
          })
          .catch(error => {
            this.$refs.modal.loading = false;
            this.$refs.modal.toggleModal = false;
            const errorData = errorResponseHandler(error);
            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorData,
                icon: "X-CircleIcon",
                variant: "danger"
              }
            });
          });
      }
    },

    switchLanguageStatus(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.$refs.disableModal.loading = true;
      if (this.languageId) {
        LanguageService.switchLanguageStatus(this.languageId)
          .then(res => {
            this.$refs.disableModal.loading = false;
            this.$refs.disableModal.toggleModal = false;
            if (resConditionCheck(res.status)) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: res.data.message,
                  icon: "CheckIcon",
                  variant: "success"
                }
              });
            }
          })
          .catch(error => {
            this.$refs.disableModal.loading = false;
            this.$refs.disableModal.toggleModal = false;
            const errorData = errorResponseHandler(error);
            const foundIdex = this.items.findIndex(
              item => item.id === this.languageId
            );
            this.$set(this.items[foundIdex], "isActive", this.status2);
            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorData,
                icon: "X-CircleIcon",
                variant: "danger"
              }
            });
          });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
